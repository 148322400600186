<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <!-- fees div -->
            <div class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>FEES</p>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="fees-inner">
                    <div  v-for="(fee_result,index) in fee_results" :key="index" class="container-fluid">
                        <!-- GST -->
                        <div   v-if="fee_result.name==='GST'" class="fees-details">
                            <div class="container-fluid">
                                <div  class="status1 fee-bin">
                                    <h5>GST</h5>
                                    <div class="theme-switch-wrapper1">
                                        <label class="theme-switch1" for="checkbox111">
                                            <input type="checkbox" id="checkbox111"  v-model="gst_status" maxlength="" @click="toggle_gst($event)"/>
                                            <div class="slider1"   :class="['slider1 ', gst_status === true ? 'round' : 'round disabled']">
                                                <span class="on">Active</span>
                                                <span class="off">Inactive</span>
                                             </div>
                                       </label>
                                        <div  class="bin-icon">
                                            <button  @click="update_fee(fee_result,index)"  class="sve-btn">SAVE</button>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="show_gst" class="gst-included">
                                    <p>Would you like to include GST within item prices?</p>
                                    <div class="gst-included-inner">
                                        <label class="container-gst">Yes
                                        <input type="radio"  name="radio" v-model="fee_result.is_gst"  v-bind:value="fee_result.is_gst ==fee_result.is_gst" >
                                        <span class="checkmark"></span>
                                        </label>
                                        <label class="container-gst">No
                                        <input type="radio"  name="radio"  v-model="fee_result.is_gst" v-bind:value="fee_result.is_gst ==!fee_result.is_gst"  >
                                        <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Delivery fee -->
                        <div  v-if="fee_result.name==='Delivery fee'" class="fees-details">
                            <div class="container-fluid">
                                <div class="status1 fee-bin">
                                    <h5>Delivery Fee</h5>
                                    <div class="theme-switch-wrapper1">
                                        <label class="theme-switch1" for="checkbox112">
                                            <input type="checkbox" id="checkbox112" v-model="delivery_status" maxlength="" @click="toggle_delivery($event)"/>
                                            <div   :class="['slider1 ', delivery_status === true ? 'round' : 'round disabled']">
                                                <span class="on">Active</span>
                                                <span class="off">Inactive</span>
                                            </div>
                                        </label>

                                        <div  class="bin-icon">
                                            <button  @click="update_fee(fee_result,index)"  class="sve-btn">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                                <div  v-show="show_delivery" class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Value</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="fee_result.value">
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect60">Type</label>
                                        <!-- <select class="form-control" id="exampleFormControlSelect60">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select> -->
                                        <v-select  :options="type_options"  v-model="fee_result.type" ></v-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Service Fee -->
                        <div  v-if="fee_result.name==='Service fee'" class="fees-details">
                            <div class="container-fluid">
                                <div class="status1 fee-bin">
                                    <h5>Service Fee</h5>
                                    <div class="theme-switch-wrapper1">
                                        <label class="theme-switch1" for="checkbox113">
                                            <input type="checkbox" id="checkbox113" v-model="service_status" maxlength="" @click="toggle_service($event)"/>
                                            <div class="slider1 round">
                                                <span class="on">Active</span>
                                                <span class="off">Inactive</span>
                                            </div>
                                        </label>

                                        <div  class="bin-icon">
                                            <button  @click="update_fee(fee_result,index)"  class="sve-btn">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="show_service" class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Value</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput15" placeholder="." required v-model="fee_result.value">
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect62">Type</label>
                                            <v-select  :options="type_options"  v-model="fee_result.type" ></v-select>

                                        </div>
                                    </div>
                                </div>
                                <div v-show="show_service" class="sales-channels">
                                    <label>Order Type</label>
                                    <div class="sales-channel-inner2 frst-order">
                                        <div class="switch-2">
                                            Dine-in
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn36"  v-model="fee_result.dine_in">
                                                <div :class="['slider ', fee_result.dine_in == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Take-Away
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn37"  v-model="fee_result.take_away">
                                                <div :class="['slider ', fee_result.take_away == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Delivery
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn38"  v-model="fee_result.delivery" >
                                                <div :class="['slider ', fee_result.delivery == true ? 'round' : 'round disabled']"> 
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- custom -->
                        <div v-if="fee_result.name==='custom'" class="fees-details">
                            <div class="container-fluid">
                                <div class="status1 fee-bin">
                                    <div class="theme-switch-wrapper1">
                                        <h5>Fee</h5>
                                    </div>
                                    <div  class="bin-icon">
                                        <i @click="delete_added_fees(fee_result.id,index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                        <button  class="sve-btn" @click="update_fee(fee_result,index)" >SAVE</button>
                                    </div>
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Value</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput15" placeholder="." required v-model='fee_result.value'>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect61">Type</label>
                                            <v-select  :options="type_options" v-model="fee_result.type"   ></v-select>

                                        </div>
                                    </div>
                                </div>
                                <div class="text-area-bottom">
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Description<span class="star"></span></label>
                                        <textarea maxlength="100" class="form-control" id="exampleFormControlTextarea1" rows="5" v-model="fee_result.description" ></textarea>
                                        <!-- <div id="the-count">
                                            <span id="current">35</span>
                                            <span id="maximum">/ 100</span>
                                        </div> -->
                                        <div id="the-count">
                                            <span id="current" class="limiter"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="sales-channels">
                                    <label>Order Type</label>
                                    <div class="sales-channel-inner2 frst-order">
                                        <div class="switch-2">
                                            Dine-in
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn36" v-model="fee_result.dine_in">
                                                <div    :class="['slider ', fee_result.dine_in == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Take-Away
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn37" v-model="fee_result.take_away">
                                                <div  :class="['slider ', fee_result.take_away == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Delivery
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn38" v-model="fee_result.delivery">
                                                <div :class="['slider ', fee_result.delivery == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

            <!-- custom fees div -->
            <div v-if="add_fees.length>0" class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>CUSTOM FEES</p>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="fees-inner">
                    <div  v-for="(add_fee,index) in add_fees" :key="index" class="container-fluid">
                        <div  class="fees-details">
                            <div class="container-fluid">
                                <div class="status1 fee-bin">
                                    <div class="theme-switch-wrapper1">
                                        <h5>Fee</h5>
                                    </div>
                                    <div class="bin-icon">
                                        <i @click="delete_fees(index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                        <button  @click="create_fee(add_fee,index)"  class="sve-btn">SAVE</button>
                                    </div>
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Value</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput15" placeholder="." required v-model='add_fee.value'>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect61">Type</label>
                                            <v-select  :options="type_options" v-model="add_fee.type"   ></v-select>

                                        </div>
                                    </div>
                                </div>
                                <div class="text-area-bottom">
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Description<span class="star"></span></label>
                                        <textarea maxlength="100" class="form-control" id="exampleFormControlTextarea1" rows="5" v-model="add_fee.description"></textarea>
                                        <!-- <div id="the-count">
                                            <span id="current">35</span>
                                            <span id="maximum">/ 100</span>
                                        </div> -->
                                        <!-- <div id="the-count">
                                            <span id="current" class="limiter"></span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="sales-channels">
                                    <label>Order Type</label>
                                    <div class="sales-channel-inner2 frst-order">
                                        <div class="switch-2">
                                            Dine-in
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn36" v-model="add_fee.dine_in">
                                                <div :class="['slider ', add_fee.dine_in == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Take-Away
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn37" v-model="add_fee.take_away">
                                                <div :class="['slider ', add_fee.take_away == true ? 'round' : 'round disabled']">
                                                </div>
                                            </label>
                                        </div>
                                        <div class="switch-2">
                                            Delivery
                                            <label class="switch-inner1">
                                                <input type="checkbox" id="togBtn38" v-model="add_fee.delivery">
                                                <div :class="['slider ', add_fee.delivery == true ? 'round' : 'round disabled']"> 
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="add-another-fee">
                <a href="javascript:void(0)" @click="clone_fee()">+ ADD ANOTHER FEE</a>
            </div>

            <!--   promos div -->
            <div  class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>PROMOTIONS</p>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="fees-inner">
                    <div class="container-fluid">
                        <div    v-for="(promo_result,index) in promo_results" :key="index" class="fees-details">
                            <div class="container-fluid">
                                <div class="status1 fee-bin">
                                    <h5>Fee</h5>
                                    <div class="bin-icon">
                                        <i @click="delete_added_promo(promo_result.id)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                        <button  class="sve-btn" @click="update_promo(promo_result,index)" >SAVE</button>
                                    </div>
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Name</label>
                                        <input type="text" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="promo_result.name">
                                    </div>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Code</label>
                                        <input type="text" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="promo_result.code">
                                    </div>
                                </div>
                                <div class="text-area-bottom">
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Description<span class="star"></span></label>
                                        <textarea maxlength="100" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder=".." v-model="promo_result.description"></textarea>
                                        <!-- <div id="the-count">
                                            <span id="current">35</span>
                                            <span id="maximum">/ 100</span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="image-video-inner inn-1">
                                    <div class="file-upload">
                                        <div class="image-upload-wrap inn-1" v-show="!promo_result.image">
                                            <div class="drag-text">
                                                <img  :src="require('@/assets/images/Vector.png')">
                                            </div>
                                        </div>    
                                        <div class="file-upload-content" v-show="promo_result.image">
                                            <div  >
                                                <img class="file-upload-image" :src="promo_result.image" >
                                            </div>
                                            <div class="image-tjjitle-wrap">
                                                <button type="button" @click="removePromoUpload(index)" class="remove-image">Remove <span class="image-title">Uploaded File</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Drag & Drop to upload or 
                                        <span>
                                            <button class="browse-button"> 
                                                <!-- <input type="file" id="myfile4" name="myfile" readonly>  Browse -->                                          
                                                <input type="file" class="file-upload-input" accept="image/*,video/*" @change="AddPromoImage($event,index)" name="image" ref="myFileInput" >Browse
                                            </button>
                                            <span v-if="file_error" class="error_alert">{{file_error_message}}</span>
                                        </span>
                                    </p>
                                   
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Start Date</label>
                                        <input type="date" class="form-control" id="formGroupExampleInput20" placeholder="YYYY-MM-DD" required v-model="promo_result.start_date">
                                    </div>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">End Date</label>
                                        <input type="date" class="form-control" id="formGroupExampleInput21" placeholder="YYYY-MM-DD" required v-model="promo_result.end_date">
                                    </div>
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput17">Value</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="promo_result.value">
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Type</label>
                                        <v-select  :options="type_options" v-model="promo_result.type"   ></v-select>

                                    </div>
                                </div>

                                <div class="checks">
                                    <div class="sales-channels">
                                        <label>Order Type</label>
                                        <div class="sales-channel-inner2">
                                            <div class="switch-2">
                                                Dine-in
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn33" v-model="promo_result.dine_in">
                                                    <div  :class="['slider ', promo_result.dine_in == true ? 'round' : 'round disabled']">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="switch-2">
                                                Take-Away
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn34" v-model="promo_result.take_away">
                                                    <div :class="['slider ', promo_result.take_away == true ? 'round' : 'round disabled']">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="switch-2">
                                                Delivery
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn35" v-model="promo_result.delivery">
                                                    <div :class="['slider ', promo_result.delivery == true ? 'round' : 'round disabled']"> 
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="check-limit1">
                                        <label class="container">
                                        <input type="checkbox" v-model="promo_result.featured_discount">
                                        <span class="checkmark"></span>
                                        Featured Discount
                                        </label>
                                        <label class="container">
                                        <input type="checkbox" v-model="promo_result.is_auto">
                                        <span class="checkmark"></span>
                                        Auto
                                        </label>
                                    </div>
                                </div>

                                <div class="individual-user">
                                    <label class="container">
                                    <input type="checkbox"  v-model="promo_result.individual_discount" >
                                    <span class="checkmark"></span>
                                    Individual User Account
                                    </label>
                                </div>
                                <div v-if="promo_result.individual_discount" class="select-input">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect2">Select User</label>
                                        <select class="form-control" id="exampleFormControlSelect2" v-model="promo_result.owner" >
                                            <option  v-for="(result,index) in customers_list" :key="index" v-bind:value="result"  >
                                              {{result.phone_number}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div  v-if="promo_result.used" class="user-date">
                                    <p><span>Used Date:</span>
                                    {{promo_result.used_date}}
                                    </p>
                                </div>




                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- custom promos div -->
            <div  v-if="add_promos.length>0"  class="contact-details">
                <div class="container-fluid">
                    <div class="contact-details-heading">
                        <p>CUSTOM PROMOTIONS</p>
                    </div>
                </div>
                <div class="border-bottom">
                </div>
                <div class="fees-inner">
                    <div class="container-fluid">
                        <div    v-for="(add_promo,index) in add_promos" :key="index" class="fees-details">
                            <div class="container-fluid">
                                <div class="status1 fee-bin">
                                    <h5>Fee</h5>
                                    <div  class="bin-icon">
                                        <i @click="delete_promo(index)" class="fa fa-trash-o" aria-hidden="true"> </i>
                                        <button  class="sve-btn" @click="create_promo(add_promo,index)" >SAVE</button>

                                    </div>
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Name</label>
                                        <input type="text" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="add_promo.name">
                                    </div>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Code</label>
                                        <input type="text" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="add_promo.code">
                                    </div>
                                </div>
                                <div class="text-area-bottom">
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Description<span class="star"></span></label>
                                        <textarea maxlength="100" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder=".." v-model="add_promo.description"></textarea>
                                        <!-- <div id="the-count">
                                            <span id="current">35</span>
                                            <span id="maximum">/ 100</span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="image-video-inner inn-1">
                                    <div class="file-upload">
                                        <div class="image-upload-wrap inn-1" v-show="!add_promo.image">
                                            <!-- <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" /> -->
                                            <div class="drag-text">
                                                <img  :src="require('@/assets/images/Vector.png')">
                                            </div>
                                        </div>
                                        
                                        <div class="file-upload-content" v-show="add_promo.image">
                                            <div  >
                                                <img class="file-upload-image" :src="add_promo.image" >
                                            </div>
                                            <div class="image-tjjitle-wrap">
                                                <button type="button" @click="removeCustomPromoUpload(index)" class="remove-image">Remove <span class="image-title">Uploaded File</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Drag & Drop to upload or 
                                        <span>
                                            <button class="browse-button"> 
                                                <!-- <input type="file" id="myfile4" name="myfile" readonly>  Browse -->                                          
                                                <input type="file" class="file-upload-input" accept="image/*,video/*" @change="AddCustomPromoImage($event,index)" name="image" ref="myFileInput" >Browse
                                            </button>
                                            <span v-if="file_error" class="error_alert">{{file_error_message}}</span>
                                        </span>
                                    </p>
                                   
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">Start Date</label>
                                        <input type="date" class="form-control" id="formGroupExampleInput20" placeholder="YYYY-MM-DD" required v-model="add_promo.start_date">
                                    </div>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput15">End Date</label>
                                        <input type="date" class="form-control" id="formGroupExampleInput21" placeholder="YYYY-MM-DD" required v-model="add_promo.end_date">
                                    </div>
                                </div>
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput17">Value</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput15" placeholder=".." required v-model="add_promo.value">
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Type</label>
                                        <v-select  :options="type_options" v-model="add_promo.type"   ></v-select>

                                    </div>
                                </div>
                                <div class="checks">
                                    <div class="sales-channels">
                                        <label>Order Type</label>
                                        <div class="sales-channel-inner2">
                                            <div class="switch-2">
                                                Dine-in
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn33" v-model="add_promo.dine_in">
                                                    <div :class="['slider ', add_promo.dine_in == true ? 'round' : 'round disabled']">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="switch-2">
                                                Take-Away
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn34" v-model="add_promo.take_away">
                                                    <div :class="['slider ', add_promo.take_away == true ? 'round' : 'round disabled']">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="switch-2">
                                                Delivery
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn35" v-model="add_promo.delivery">
                                                    <div :class="['slider ', add_promo.delivery == true ? 'round' : 'round disabled']"> 
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="check-limit1">
                                        <label class="container">
                                        <input type="checkbox" v-model="add_promo.featured_discount">
                                        <span class="checkmark"></span>
                                        Featured Discount
                                        </label>
                                        <label class="container">
                                        <input type="checkbox" v-model="add_promo.is_auto">
                                        <span class="checkmark"></span>
                                        Auto
                                        </label>
                                    </div>
                                </div>

                                <div class="individual-user">
                                    <label class="container">
                                    <input type="checkbox"  v-model="add_promo.individual_discount" >
                                    <span class="checkmark"></span>
                                    Individual User Account
                                    </label>
                                </div>
                                <div v-if="add_promo.individual_discount" class="select-input">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect2">Select User</label>
                                        <select class="form-control" id="exampleFormControlSelect2" v-model="add_promo.owner" >
                                            <option  v-for="(result,index) in customers_list" :key="index" v-bind:value="result"  >
                                              {{result.phone_number}}</option>
                                        </select>
                                    </div>
                                </div>



                            </div>
                        </div>
                        
                     

                    </div>
                </div>
            </div>

            <div class="add-another-fee">
                <a href="javascript:void(0)" @click="clone_promo()">+ ADD ANOTHER DISCOUNT</a>
            </div>
          
        </div>
        <!-- <div class="buttons">
            <button @click="discard_intro()" class="button1">DISCARD CHANGES</button>
            <button  @click="update_fee_and_promos()" class="button2">SAVE CHANGES</button>
        </div> -->
    </div>
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'FeeAndPromos',
        data () {
            return {
                loading:false,

                errors:[],
                
                file:'',
                file_error:false,
                file_error_message:'',

                gst_status:true,
                show_gst:true,

                delivery_status:true,
                show_delivery:true,

                service_status:true,
                show_service:true,
              
                type_options:[
                    'flat',
                    'percent'
                ],
                fee_results:[],
                promo_results:[],

                add_fees:[],
                add_promos:[],

               customers_list:[],
            }
            },
        methods: {
            // fee methods
            toggle_gst(e){
                if(e.target.checked== true){
                    this.show_gst=true
                }else{
                    this.show_gst=false
                }
            },

            toggle_delivery(e){
                if(e.target.checked== true){
                    this.show_delivery=true
                }else{
                    this.show_delivery=false
                }
            },

            toggle_service(e){
                if(e.target.checked== true){
                    this.show_service=true
                }else{
                    this.show_service=false
                }
            },

            clone_fee(){
                this.add_fees.push({
                    name:'',
                    value:'',
                    type:'',
                    description:'',
                    fee_type:'custom',
                    dine_in:false,
                    take_away:false,
                    delivery:false,
                })
            },

            delete_fees(index){
                this.add_fees.splice(index,1)
            },

            async update_fee(fee_data,index){
                try{
                    this.errors=[]
                    fee_data.order_type=[]
                    if (fee_data.dine_in==true){
                        fee_data.order_type.push('dine_in')
                    }
                    if (fee_data.take_away==true){
                        fee_data.order_type.push('take_away')
                    } if (fee_data.delivery==true){
                        fee_data.order_type.push('delivery')
                    }
                   
                    if (this.validate_fee(fee_data)) {
                        this.loading= true;
                        const data =  { 
                            "brand": this.$store.state.brand, 
                            "description":  fee_data.description, 
                            "is_gst":  fee_data.is_gst, 
                            "type": fee_data.type, 
                            "value":  fee_data.value, 
                            "order_type":fee_data.order_type
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/fees/`+fee_data.id+`/`
                        const response =  await axios.patch(api_url,data,{headers})
                        this.loading= false;
                        // console.log(response.data);
                        await this.$toasted.success("Fee Updated Successfully");

                        var result=response.data
                        // dine in
                        if(result.order_type.includes("dine_in")){
                            result.dine_in=true
                        }else{
                            result.dine_in=false
                        }
                        // take_away
                        if(result.order_type.includes("take_away")){
                            result.take_away=true
                        }else{
                            result.take_away=false
                        } 
                        // delivery
                        if(result.order_type.includes("delivery")){
                            result.delivery=true
                        }else{
                            result.delivery=false
                        }
                        this.fee_results[index]=result
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            

            },

            async create_fee(fee_data,index){
                try{
                    this.errors=[]
                    fee_data.order_type=[]
                    if (fee_data.dine_in==true){
                        fee_data.order_type.push('dine_in')
                    }
                    if (fee_data.take_away==true){
                        fee_data.order_type.push('take_away')
                    } if (fee_data.delivery==true){
                        fee_data.order_type.push('delivery')
                    }
                    if (this.validate_fee(fee_data)) {
                        this.loading= true;
                        const data =  { 
                            "brand": this.$store.state.brand, 
                            "description":  fee_data.description, 
                            "is_gst":  fee_data.is_gst, 
                            "type": fee_data.type, 
                            "value":  fee_data.value, 
                            "order_type":fee_data.order_type,
                            "name":"custom"
                        };
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/fees/`
                        const response =  await axios.post(api_url,data,{headers})
                        this.loading= false;
                        // console.log(response.data);
                        await this.$toasted.success("Fee Created Successfully");
                        var result=response.data
                        // dine in
                        if(result.order_type.includes("dine_in")){
                            result.dine_in=true
                        }else{
                            result.dine_in=false
                        }
                        // take_away
                        if(result.order_type.includes("take_away")){
                            result.take_away=true
                        }else{
                            result.take_away=false
                        } 
                        // delivery
                        if(result.order_type.includes("delivery")){
                            result.delivery=true
                        }else{
                            result.delivery=false
                        }
                        this.fee_results.push(result)
                        this.add_fees.splice(index,1)
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            validate_fee(fee_data){
                if( (!fee_data.dine_in) && (!fee_data.take_away) && (!fee_data.delivery) ) {
                    this.errors.push("Please provide order type!")
                    this.$toasted.error('Please provide order type!');    
                }
                if(!fee_data.value){
                    this.errors.push("Please provide value!")
                    this.$toasted.error('Please provide value!');    
                }    
                if(!fee_data.type){
                    this.errors.push("Please provide type!")
                    this.$toasted.error('Please provide type!');   
                } 
                if (!this.errors.length) {
                    return true;
                }
            },

            // discount methods
            clone_promo(){
                this.add_promos.push({
                    name:'',
                    code:'',
                    description:'',
                    image:'',
                    start_date:'',
                    end_date:'',
                    value:'',
                    type:'',
                    featured_discount:'',
                    is_auto:'',
                    dine_in:false,
                    take_away:false,
                    delivery:false,
                    individual_discount:false,
                    owner:''

                })
            },

            delete_promo(index){
                this.add_promos.splice(index,1)
            },

            removePromoUpload(i){
                this.promo_results[i].image='';
                this.file = '';
                this.$refs.myFileInput.value = '';
            },

            removeCustomPromoUpload(i){
                this.add_promos[i].image='';
                this.file = '';
                this.$refs.myFileInput.value = '';
            },

            AddPromoImage(e,i){
                this.file_error=false
                var cover_files = e.target.files || e.dataTransfer.files;
                if (!cover_files.length)
                    return;
                // checking type of file
                if(cover_files[0]['type'].split('/')[0] != 'image' ){
                    this.file_error=true
                    this.file_error_message="please upload valid file type: image"
                    this.$toasted.error("please upload valid file type: image")
                    return;
                }
                // checking size of file
                var size = parseFloat(cover_files[0].size / 1024).toFixed(2);
                if(size>500){
                    this.$toasted.error('Image size should not be more than 500KB')
                    return;
                }
 
                this.file = cover_files[0];
                let reader = new FileReader();
                let that = this;
                reader.onload = function (e) {
                    that.promo_results[i].image=e.target.result;
                    that.promo_results[i].file=cover_files[0]
                }
                reader.readAsDataURL(cover_files[0]); 
            },

            AddCustomPromoImage(e,i){
                this.file_error=false
                var cover_files = e.target.files || e.dataTransfer.files;
                if (!cover_files.length)
                    return;
                // checking type of file
                if(cover_files[0]['type'].split('/')[0] != 'image' ){
                    this.file_error=true
                    this.file_error_message="please upload valid file type: image"
                    this.$toasted.error("please upload valid file type: image")
                    return;
                }
                // checking size of file
                var size = parseFloat(cover_files[0].size / 1024).toFixed(2);
                if(size>500){
                    this.$toasted.error('Image size should not be more than 500KB')
                    return;
                }

                this.file = cover_files[0];
                let reader = new FileReader();
                let that = this;
                reader.onload = function (e) {
                    that.add_promos[i].image=e.target.result;
                    that.add_promos[i].file=cover_files[0]
                }
                reader.readAsDataURL(cover_files[0]);
            },

            validate_promo(promo_data){
                if( (!promo_data.dine_in) && (!promo_data.take_away) && (!promo_data.delivery) ) {
                    this.errors.push("Please provide order type!")
                    this.$toasted.error('Please provide order type!');    
                }
                if(!promo_data.value){
                    this.errors.push("Please provide value!")
                    this.$toasted.error('Please provide value!');    
                }    
                if(!promo_data.type){
                    this.errors.push("Please provide type!")
                    this.$toasted.error('Please provide type!');   
                } 
                if((promo_data.individual_discount) && (!promo_data.owner)    ){
                    this.errors.push("Please select a user to apply promo code!")
                    this.$toasted.error('Please select a user to apply promo code!');   
                } 
                
                if (!this.errors.length) {
                    return true;
                }

         
            },

            async update_promo(promo_data,index){                
                try{
                    this.errors=[]
                    promo_data.order_type=[]
                    if (promo_data.dine_in==true){
                        promo_data.order_type.push('dine_in')
                    }
                    if (promo_data.take_away==true){
                        promo_data.order_type.push('take_away')
                    } if (promo_data.delivery==true){
                        promo_data.order_type.push('delivery')
                    }
                    if (this.validate_promo(promo_data)) {
                        this.loading= true;
                        let formData = new FormData();
                        formData.append('brand', promo_data.brand);
                        formData.append('code', promo_data.code);
                        formData.append('description', promo_data.description);
                        formData.append('end_date', promo_data.end_date);
                        formData.append('featured_discount', promo_data.featured_discount);
                        formData.append('is_auto', promo_data.is_auto);
                        formData.append('name', promo_data.name);
                        formData.append('start_date', promo_data.start_date);
                        formData.append('type', promo_data.type);
                        formData.append('value', promo_data.value);
                        if( promo_data.individual_discount && promo_data.owner){
                            formData.append('individual_discount', promo_data.individual_discount);
                            formData.append('owner', promo_data.owner.id);
                        }
                        if(promo_data.file){
                            formData.append('image', promo_data.file);
                        }
                        var i;
                        for( i = 0; i < promo_data.order_type.length; i++ ){
                            formData.append('order_type',  promo_data.order_type[i]);
                        }
                        const headers = { 
                            "Content-Type": "multipart/form-data",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/discounts/`+promo_data.id+`/`
                        const response =  await axios.patch(api_url,formData,{headers})
                        this.loading= false;
                        await this.$toasted.success("Promo Updated Successfully");
                        var result=response.data
                        // dine in
                        if(result.order_type.includes("dine_in")){
                            result.dine_in=true
                        }else{
                            result.dine_in=false
                        }
                        // take_away
                        if(result.order_type.includes("take_away")){
                            result.take_away=true
                        }else{
                            result.take_away=false
                        } 
                        // delivery
                        if(result.order_type.includes("delivery")){
                            result.delivery=true
                        }else{
                            result.delivery=false
                        }      
                        if(result.owner){
                            var data = this.customers_list.find(val => val.id ===result.owner)
                            result.owner=data
                        }
                        this.promo_results[index]=result
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            },

            async create_promo(promo_data,index){
                try{
                this.errors=[]
                    promo_data.order_type=[]
                    if (promo_data.dine_in==true){
                        promo_data.order_type.push('dine_in')
                    }
                    if (promo_data.take_away==true){
                        promo_data.order_type.push('take_away')
                    } if (promo_data.delivery==true){
                        promo_data.order_type.push('delivery')
                    }
                   
                    if (this.validate_promo(promo_data)) {
                        this.loading= true;
                        let formData = new FormData();
                        formData.append('brand', this.$store.state.brand);
                        formData.append('code', promo_data.code);
                        formData.append('description', promo_data.description);
                        formData.append('end_date', promo_data.end_date);
                        formData.append('featured_discount', promo_data.featured_discount);
                        formData.append('is_auto', promo_data.is_auto);
                        formData.append('name', promo_data.name);
                        formData.append('start_date', promo_data.start_date);
                        formData.append('type', promo_data.type);
                        formData.append('value', promo_data.value);
                        if( promo_data.individual_discount && promo_data.owner){
                            formData.append('individual_discount', promo_data.individual_discount);
                            formData.append('owner', promo_data.owner.id);
                        }

                        if(promo_data.file){
                            formData.append('image', promo_data.file);
                        }
                        var i;
                        for( i = 0; i < promo_data.order_type.length; i++ ){
                            formData.append('order_type',  promo_data.order_type[i]);
                        }
                        const headers = { 
                            "Content-Type": "multipart/form-data",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/discounts/`
                        const response =  await axios.post(api_url,formData,{headers})
                        this.loading= false;
                        await this.$toasted.success("Promo Created Successfully");
                        var result=response.data
                        // dine in
                        if(result.order_type.includes("dine_in")){
                            result.dine_in=true
                        }
                        else{
                            result.dine_in=false
                        }
                        // take_away
                        if(result.order_type.includes("take_away")){
                            result.take_away=true
                        }
                        else{
                            result.take_away=false
                        } 
                        // delivery
                        if(result.order_type.includes("delivery")){
                            result.delivery=true
                        }
                        else{
                            result.delivery=false
                        }
                        if(result.owner){
                            var data = this.customers_list.find(val => val.id ===result.owner)
                            result.owner=data
                        }
                        this.promo_results.push(result)
                        this.add_promos.splice(index,1)
                    }
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
            
            },

            async delete_added_promo(promo_id,index){
                this.loading= true;
                try{
                    const headers = { 
                        // "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var api_url = `${baseUrl}/api/discounts/`+promo_id+`/`
                    const response =  await axios.delete(api_url,{headers})
                    console.log(response.data)
                    this.loading= false;
                    await this.$toasted.success("Promo Deleted Successfully");
                    this.promo_results.splice(index,1)
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }

            },

            async delete_added_fees(fee_id,index){
                   this.loading= true;
                    try{
                        const headers = { 
                            // "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        var api_url = `${baseUrl}/api/fees/`+fee_id+`/`
                        const response =  await axios.delete(api_url,{headers})
                        this.loading= false;
                        console.log(response.data);
                        await this.$toasted.success("Fee Deleted Successfully");
                        this.fee_results.splice(index,1)
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }
            }
        },
        mounted(){

        },
        async created() {
            var i;
            // hitting api to get fee data
            var brand_id = this.$store.state.brand
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const params =  {  "brand": brand_id};
                var api_url = `${baseUrl}/api/fees/`
                const response =  await axios.get(api_url,{params: params,headers:headers})
                this.loading= false;
                var result=response.data.results
                console.log('--------fees data-------',result)
                for (i = 0; i < result.length; ++i) {
                    // dine in
                    if(result[i].order_type.includes("dine_in")){
                        result[i].dine_in=true
                    }else{
                        result[i].dine_in=false
                    }
                    // take_away
                    if(result[i].order_type.includes("take_away")){
                        result[i].take_away=true
                    }else{
                        result[i].take_away=false
                    } 
                    // delivery
                    if(result[i].order_type.includes("delivery")){
                        result[i].delivery=true
                    }else{
                        result[i].delivery=false
                    }
                }
                this.fee_results=result
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

            // hitting api to customers data
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const params =  {  "brand": brand_id};
                var url = `${baseUrl}/api/customers/`
                const response =  await axios.get(url,{params: params,headers:headers})
                this.loading= false;

            
                this.customers_list=response.data
                if(this.customers_list){
                    for (i = 0; i < this.customers_list.length; ++i) {
                        if(!this.customers_list[i].phone_number){
                            this.customers_list.splice(i,1)
                        }
                    }
                }
                

            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

            // hitting api to get discounts data
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const params =  {  "brand": brand_id};
                var my_api_url = `${baseUrl}/api/discounts/`
                const response =  await axios.get(my_api_url,{params: params,headers:headers})
                this.loading= false;

                var my_result=response.data.results
                console.log('--------------discounts data--------------',my_result)
                for (i = 0; i < my_result.length; ++i) {
                    // dine in
                    if(my_result[i].order_type.includes("dine_in")){
                        my_result[i].dine_in=true
                    }else{
                        my_result[i].dine_in=false
                    }
                    // take_away
                    if(my_result[i].order_type.includes("take_away")){
                        my_result[i].take_away=true
                    }else{
                        my_result[i].take_away=false
                    } 
                    // delivery
                    if(my_result[i].order_type.includes("delivery")){
                        my_result[i].delivery=true
                    }else{
                        my_result[i].delivery=false
                    }

                    if(my_result[i].owner){
                        var data = this.customers_list.find(val => val.id === my_result[i].owner)
                        my_result[i].owner=data
                        


                    }

                   



                }
                this.promo_results=my_result
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }

        },
        computed: {
            // charactersLeft() {
            // var char = this.fee_description.length,
            // limit = 300;
            // return (char) + " / " + limit ;
            // }
        }
    }

</script>


<style>

</style>